'use client'

import { GlobalType } from '@/types'
import { motion } from 'framer-motion'
import { Button } from '../button'
import HeaderItem from './header.item'
import { HeaderVariantReturnProps } from './header.variants'
import useHeader from './use-header'

type HeaderNavProps = {
  styles: HeaderVariantReturnProps
  siteMenu?: GlobalType['navbarTop']['navbarItem']
  studioMenu?: GlobalType['navbarTop']['navbarItem']
}

const HeaderNav = ({
  styles,
  siteMenu = [],
  studioMenu = []
}: HeaderNavProps) => {
  const { onHoverDropdown, solidButton } = useHeader()

  return (
    <nav id="header-nav" className={styles.nav()}>
      <div className={styles.group()}>
        <ul className={styles.list()}>
          {siteMenu.map(({ id, title, links }) => (
            <motion.li
              key={id}
              className={styles.item()}
              onMouseEnter={() => onHoverDropdown(`${id}`)}
              onMouseLeave={() => onHoverDropdown(false)}
            >
              <HeaderItem
                styles={styles}
                id={`${id}`}
                title={title}
                links={links}
              />
            </motion.li>
          ))}
        </ul>
      </div>
      <div className={styles.group()}>
        <ul className={styles.list()}>
          {studioMenu.map(({ id, title, links }, i) => (
            <li key={id} className="lg:md-auto group relative w-full">
              <Button
                sectionName={`header_${title}`}
                href={links[0].linkUrl}
                title={title}
                size="sm"
                weight={i === solidButton ? 'medium' : 'medium'}
                color={i === solidButton ? 'white' : 'whiteAcqua'}
                appearance={i === solidButton ? 'solid' : 'ghost'}
                tabIndex={0}
                fluidOnMobile
              >
                {title}
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  )
}

export default HeaderNav
