import { motion } from 'framer-motion'
import { Share } from '../share'
import { headerShare } from './header.variants'
import useHeader from './use-header'

const variants = {
  hidden: { y: -76, opacity: 0 },
  visible: { y: 0 },
  visible_with_menu: { y: 80 }
}

const HeaderShareBar = () => {
  const { share, scrollYProgress } = useHeader()

  const styles = headerShare()

  return (
    <motion.aside
      className={styles.base()}
      variants={variants}
      initial={{ y: -76 }}
      animate={share}
      transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.4 }}
    >
      <div className={styles.content()}>
        <Share />
      </div>
      <div className={styles.progress()}>
        <motion.div
          className={styles.currentProgress()}
          style={{ scaleX: scrollYProgress, transformOrigin: 0 }}
        />
      </div>
    </motion.aside>
  )
}

export default HeaderShareBar
