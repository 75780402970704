'use client'

import { Button } from '../button'
import { Text } from '../text'
import {
  useFloatingBanner,
  useFloatingBannerProps
} from './use-floating-banner'

export type FloatingBannerProps = useFloatingBannerProps

const FloatingBanner = (props: FloatingBannerProps) => {
  const { className, styles, show, onCloseBanner, data } =
    useFloatingBanner(props)
  const { body, confirmLabel, policy } = data

  if (!show) {
    return null
  }

  return (
    <div className={styles.base({ className })}>
      <div className={styles.banner()}>
        <div className={styles.body()}>
          <Text size="tinyBody" weight="medium" color="text-white">
            {body}
          </Text>
        </div>
        <div className={styles.policy()}>
          {policy?.url && (
            <Button
              sectionName={`floating_banner_${policy.text}`}
              appearance="outline"
              color="white"
              size="sm"
              fluidOnMobile
              newTab
              href={policy.url}
              title={policy.text}
            >
              {policy.text}
            </Button>
          )}
          {confirmLabel && (
            <Button
              type="button"
              appearance="solid"
              color="white"
              size="sm"
              fluidOnMobile
              onClick={onCloseBanner}
            >
              {confirmLabel}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default FloatingBanner
