'use client'

import { SvgName } from '@/svg'
import { Icon } from '@/components/ui/icon'
import { social } from './social.variants'

export type SocialProps = {
  className?: string
  data?: Record<string, string | number>
}

const Social = ({ className = '', data = {} }: SocialProps) => {
  const styles = social()

  return (
    <ul className={styles.base({ className })}>
      {Object.entries(data).map(([provider, url]) => {
        if (provider === 'id') return null

        return (
          <li key={provider} className={styles.item()}>
            <a
              href={url as string}
              className={styles.link()}
              target="_blank"
              title={provider}
            >
              <span className="sr-only">{provider}</span>
              <Icon
                name={provider.toLowerCase() as SvgName}
                className={styles.icon()}
                aria-hidden="true"
              />
            </a>
          </li>
        )
      })}
    </ul>
  )
}

export default Social
