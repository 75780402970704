/* eslint-disable @next/next/no-img-element */
'use client'

import { Button } from '../button'
import { Text } from '../text'
import NextImage from 'next/image'
import { useCigarBar, useCigarBarProps } from './use-cigar-bar'

export type CigarBarProps = useCigarBarProps

const CigarBar = (props: CigarBarProps) => {
  const { ref, className, styles, data } = useCigarBar(props)

  return (
    // @ts-ignore
    <div ref={ref} className={styles.base({ className })}>
      {!!data && (
        <div className={styles.wrapper()}>
          <div className={styles.container()}>
            <div className={styles.imgTextWrapper()}>
              {!!data?.background && (
                <NextImage
                  className={styles.image()}
                  src={data?.background.url}
                  width={data?.background.width}
                  height={data?.background.height}
                  alt={data?.background.alternativeText}
                />
              )}
              {!!data?.text && (
                <div className={styles.textWrapper()}>
                  <Text
                    className={styles.text()}
                    size="cigarbar"
                    color="text-white/80"
                    weight="medium"
                  >
                    {data.text}
                  </Text>
                </div>
              )}
            </div>
            {!!data?.link && (
              <Button
                size="sm"
                color="white"
                appearance="solid"
                href={data.link.url}
                title={data.link.text}
                className={styles.action()}
              >
                {data.link.text}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default CigarBar
