'use client'

import { locales } from '@/config/locales'
import { UseLangSwitcherProps, useLangSwitcher } from './use-lang-switcher'

export type LangSwitcherProps = UseLangSwitcherProps

const LangSwitcher = (props: LangSwitcherProps) => {
  const { styles, className, currentLocale, onChangeLocale, isPending } =
    useLangSwitcher(props)

  return (
    <div className={styles.base({ className })}>
      <label htmlFor="locales" className="sr-only">
        Locale
      </label>
      <select
        id="locales"
        defaultValue={currentLocale}
        className={styles.select()}
        onChange={onChangeLocale}
        disabled={isPending}
      >
        {Object.entries(locales).map(([key, item]) => {
          return (
            <option key={key} value={item.lang}>
              {item.label}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default LangSwitcher
