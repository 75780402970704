import { useMemo } from 'react'
import { hamburger } from './hamburger.variants'

export type HamburgerProps = {
  className?: string
  active: boolean
  ariaControls: string
  toggle: () => void
}

const Hamburger = ({
  className = '',
  active = false,
  ariaControls,
  toggle
}: HamburgerProps) => {
  const ariaLabel = active ? 'Open menu' : 'Close menu'

  const styles = useMemo(() => hamburger({ active }), [active])

  return (
    <button
      className={styles.base({ className })}
      type="button"
      aria-label={ariaLabel}
      aria-controls={ariaControls}
      aria-expanded={active}
      onClick={toggle}
    >
      <i aria-hidden="true" className={styles.bar()}></i>
    </button>
  )
}

export default Hamburger
