import { tv } from '@/utils/tv'

export const langSwitcher = tv(
  {
    slots: {
      base: ['max-w-[200px]'],
      select: [
        'w-full',
        'block',
        'rounded-md',
        'text-display-16 font-medium',
        'py-2 pl-4 pr-8',
        'outline-none appearance-none',
        'bg-transparent text-gray-60',
        'border-2 border-gray-100 focus:border-gray-100',
        'focus:outline-offset-2 focus:outline-acqua-500 focus:ring-0',
        'bg-select-arrow bg-no-repeat bg-[right_12px_center] bg-[length:10px_6px]'
      ]
    }
  },
  { twMerge: false }
)
