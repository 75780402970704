'use client'

import { providers } from '@/config/share'
import { SvgName } from '@/svg'
import { Button } from '@/components/ui/button'
import { useShare, useShareProps } from './use-share'

export type ShareProps = useShareProps

const Share = (props: ShareProps) => {
  const { className, onShareUrl, styles } = useShare(props)

  return (
    <ul className={styles.base({ className })}>
      {providers.map(({ title, url }) => {
        const provider = title.toLowerCase() as SvgName

        return (
          <li key={title} className={styles.item()}>
            <Button
              size="xs"
              appearance="solid"
              color="grayDark"
              leftIcon={provider}
              type="button"
              rounded
              aria-label={title}
              onClick={() => onShareUrl(url, provider)}
            />
          </li>
        )
      })}
    </ul>
  )
}

export default Share
