'use client'

import { useEffect } from 'react'

const setOverflow = (element: HTMLElement | null, value: string) => {
  if (element) {
    element.style.overflow = value
  }
}

const useDisableScroll = <T extends HTMLElement>(
  shouldLock: boolean,
  ref: React.RefObject<T>
) => {
  useEffect(() => {
    const exceptionRef = ref.current
    const originalBodyOverflow = window.getComputedStyle(document.body).overflow
    const originalRefOverflow = exceptionRef
      ? window.getComputedStyle(exceptionRef).overflow
      : ''

    if (shouldLock) {
      setOverflow(document.body, 'hidden')
      setOverflow(
        exceptionRef,
        originalRefOverflow === 'hidden' ? 'auto' : originalRefOverflow
      )
    } else {
      setOverflow(document.body, originalBodyOverflow)
      setOverflow(exceptionRef, originalRefOverflow)
    }

    return () => {
      setOverflow(document.body, originalBodyOverflow)
      setOverflow(exceptionRef, originalRefOverflow)
    }
  }, [shouldLock, ref])
}

export default useDisableScroll
