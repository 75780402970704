const providers = [
  {
    title: 'WhatsApp',
    url: 'https://wa.me/send?text={shareRefer}'
  },
  {
    title: 'Telegram',
    url: 'https://t.me/share/url?url={shareRefer}'
  },
  {
    title: 'Twitter',
    url: 'https://twitter.com/intent/tweet?url={shareRefer}'
  },
  {
    title: 'LinkedIn',
    url: 'https://linkedin.com/shareArticle?mini=true&url={shareRefer}'
  },
  {
    title: 'Facebook',
    url: 'https://facebook.com/sharer/sharer.php?u={shareRefer}'
  }
]

export { providers }
