import { tv, VariantProps } from '@/utils/tv'

export const floatingBanner = tv({
  slots: {
    base: [
      'fixed bottom-4 inset-x-0 z-50',
      'max-w-7xl mx-auto',
      'px-6 lg:px-8'
    ],
    banner: [
      'flex flex-col md:flex-row gap-4',
      'items-center justify-between flex-wrap',
      'rounded-xl',
      'p-4 md:p-3',
      'bg-gray-200',
      'shadow-lg'
    ],
    body: ['flex-1 flex items-center', 'md:pl-1'],
    policy: ['flex flex-col xs:flex-row gap-2', 'w-full md:w-auto']
  }
})

export type FloatingBannerVariantProps = VariantProps<typeof floatingBanner>
