/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'next/navigation'
import { useLocalStorage } from 'react-use'
import {
  FloatingBannerVariantProps,
  floatingBanner
} from './floating-banner.variants'

export type useFloatingBannerProps = FloatingBannerVariantProps & {
  className?: string
  body: string
  confirmLabel: string
  policy?: {
    text: string
    url: string
  }
}

export const useFloatingBanner = (props: useFloatingBannerProps) => {
  const { className = '', policy, ...componetProps } = props
  const [show, setShow] = useState(false)
  const [value, setValue] = useLocalStorage<boolean>('CLOSE_COOKIE_POLICY')
  const searchParams = useSearchParams()

  const styles = floatingBanner()

  const hideBanner = useMemo(
    () => searchParams.get('att') === '0',
    [searchParams]
  )

  useEffect(() => {
    if (hideBanner) {
      setValue(true)
    }

    if (value === true) {
      setShow(false)
    } else if (!value) {
      setShow(true)
    }
  }, [hideBanner, value])

  const onCloseBanner = () => {
    setValue(true)
    setShow(false)
  }

  return {
    className,
    styles,
    show,
    onCloseBanner,
    data: {
      ...componetProps,
      policy
    }
  }
}
