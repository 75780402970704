'use client'

import { usePathname } from 'next/navigation'
import { EventAction } from '@/config/events'
import { slugify } from '@/utils/text'
import { baseUrl } from '@/utils/env'
import { dispatchGoogleEvent } from '@/utils/google'
import { ShareVariantProps, share } from './share.variants'

export type useShareProps = ShareVariantProps & {
  className?: string
}

export const useShare = (props: useShareProps) => {
  const { className = '' } = props

  const pathname = usePathname()
  const styles = share()

  const onShareUrl = (url: string, provider: string) => {
    let shareRefer = `${baseUrl}${pathname}`

    const slug = slugify(document?.title)

    dispatchGoogleEvent(`${EventAction.WEBSITE_SHARE}_${provider}`, {
      label: `post_${slug}`
    })

    const shareUrl = url.replace('{shareRefer}', shareRefer)

    window.open(shareUrl)
  }

  return {
    className,
    styles,
    onShareUrl
  }
}
