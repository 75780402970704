'use client'

import { useMemo } from 'react'
import NextLink from 'next/link'
import { motion } from 'framer-motion'
import { EventAction } from '@/config/events'
import useUTMParams from '@/hooks/use-utm-params'
import { GlobalLink } from '@/types'
import { slugify } from '@/utils/text'
import { Button } from '../button'
import { HeaderVariantReturnProps } from './header.variants'
import useHeader from './use-header'
import { Icon } from '../icon'

type HeaderItemProps = {
  styles: HeaderVariantReturnProps
  id: string
  title: string
  links: GlobalLink[]
}

const desktopDropdownVariants = {
  enter: {
    opacity: 1,
    x: '-50%',
    y: 0,
    display: 'block',
    transition: {
      duration: 0.3
    }
  },
  exit: {
    opacity: 0,
    x: '-50%',
    y: -10,
    display: 'none'
  }
}

const HeaderItem = ({ styles, id, title, links = [] }: HeaderItemProps) => {
  const { isDesktopMenu, expanded, opened, onHandleMenu } = useHeader()
  const { appendUTMParams } = useUTMParams()

  const isExpanded = useMemo(() => expanded === id, [id, expanded])

  const hasDropdown = links.length > 1
  const dropdownLabel = `dropdown-${title}`

  let buttonProps = {}

  if (hasDropdown) {
    buttonProps = {
      id: dropdownLabel,
      className: 'group-hover:desktop-menu:text-acqua-500',
      'aria-expanded': isExpanded,
      'aria-haspopup': 'true'
    }
  } else {
    buttonProps = {
      href: links[0].linkUrl,
      title
    }
  }

  return (
    <>
      <Button
        {...buttonProps}
        size={isDesktopMenu ? 'sm' : 'xs'}
        appearance={isDesktopMenu ? 'ghost' : 'text'}
        sectionName={`header_${title}`}
        color="whiteAcqua"
        weight="medium"
        tabIndex={0}
        onClick={() => {
          if (opened) {
            onHandleMenu(false)
          }
        }}
      >
        {title}
      </Button>
      {hasDropdown && (
        <motion.div
          className={styles.dropdown()}
          initial="exit"
          variants={desktopDropdownVariants}
          animate={isExpanded ? 'enter' : 'exit'}
          aria-labelledby={dropdownLabel}
        >
          <ul className={styles.dropdownList()}>
            {links.map((item, i) => {
              const slugifiedSectionName = slugify(`header_${item.title}`)

              return (
                <li key={`${title}-${i}`}>
                  <NextLink
                    href={appendUTMParams(
                      item.linkUrl,
                      slugify(slugifiedSectionName)
                    )}
                    title={item.title}
                    className={styles.dropdownLink()}
                    onClick={() => {
                      if (opened) {
                        onHandleMenu(false)
                      }
                    }}
                    tabIndex={0}
                    data-event-action={EventAction.WEBSITE_CLICK}
                    data-event-label={slugifiedSectionName}
                  >
                    {item?.icon && item.icon?.name && (
                      <Icon name={item.icon.name} className={styles.icon()} />
                    )}
                    {item.title}
                  </NextLink>
                </li>
              )
            })}
          </ul>
        </motion.div>
      )}
    </>
  )
}

export default HeaderItem
