import { ChangeEvent, useTransition, useContext } from 'react'
import { AlternateURLContext } from '@/contexts/AlternateURLProvider'
import { useRouter, useSearchParams } from 'next/navigation'
import { Locale } from '@/config/locales'
import { type Alternate } from '@/types/strapi/util'
import { langSwitcher } from './lang-switcher.variants'

export interface UseLangSwitcherProps {
  className?: string
  locale: Locale
}

export const useLangSwitcher = ({
  className = '',
  locale: currentLocale
}: UseLangSwitcherProps) => {
  const defaultLocale = 'en'
  const [isPending, startTransition] = useTransition()
  const { push } = useRouter()
  const searchParams = useSearchParams().toString()
  const { state } = useContext(AlternateURLContext)

  const finalSearchParams = searchParams ? `?${searchParams}` : ''

  const styles = langSwitcher()

  const onChangeLocale = (event: ChangeEvent<HTMLSelectElement>) => {
    const newLocale = event.target.value

    let newLocaleAlternate = state.alternates.find(
      (alternate) => alternate.locale === newLocale
    )

    if (!newLocaleAlternate) {
      newLocaleAlternate = state.alternates.find(
        (alternate) => alternate.locale === defaultLocale
      )
    }

    startTransition(() => {
      push(`${newLocaleAlternate?.path}${finalSearchParams}`)
    })
  }

  return { styles, className, isPending, currentLocale, onChangeLocale }
}
