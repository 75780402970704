/* eslint-disable react-hooks/exhaustive-deps */
'use client'

import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState
} from 'react'
import { type Alternate } from '@/types/strapi/util'

export type AlternateURLStateProps = {
  alternates: Alternate[]
}

type AlternateURLContextProps = {
  state: AlternateURLStateProps
  setState: Dispatch<SetStateAction<AlternateURLStateProps>>
}

type AlternateURLProviderProps = {
  children: ReactNode
}

const initialAlternateURLState: AlternateURLStateProps = {
  alternates: []
}

const AlternateURLContext = createContext<AlternateURLContextProps>({
  state: initialAlternateURLState,
  setState: () => {}
})

const AlternateURLProvider = ({ children }: AlternateURLProviderProps) => {
  const [state, setState] = useState(initialAlternateURLState)

  return (
    <AlternateURLContext.Provider value={{ state, setState }}>
      {children}
    </AlternateURLContext.Provider>
  )
}

export { AlternateURLContext, AlternateURLProvider }
