/* eslint-disable react-hooks/exhaustive-deps */
'use client'

import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState
} from 'react'

export type HeaderStateProps = {
  blur: boolean
  share: 'hidden' | 'visible' | 'visible_with_menu'
  hidden: boolean
  opened: boolean
  expanded: false | string
  solidButton: number
}

type HeaderContextProps = {
  state: HeaderStateProps
  setState: Dispatch<SetStateAction<HeaderStateProps>>
}

type HeaderProviderProps = {
  children: ReactNode
}

const initialHeaderState: HeaderStateProps = {
  blur: false,
  share: 'hidden',
  hidden: false,
  opened: false,
  expanded: false,
  solidButton: 1
}

const HeaderContext = createContext<HeaderContextProps>({
  state: initialHeaderState,
  setState: () => {}
})

const HeaderProvider = ({ children }: HeaderProviderProps) => {
  const [state, setState] = useState(initialHeaderState)

  return (
    <HeaderContext.Provider value={{ state, setState }}>
      {children}
    </HeaderContext.Provider>
  )
}

export { HeaderContext, HeaderProvider }
