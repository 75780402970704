import { Locale, i18n } from '@/config/locales'

export const createLocalizableTag = (tag: string, locale: Locale) => {
  return `${tag}-${locale}`
}

export const getLocalizedPath = (slug: string, locale: Locale) => {
  const pathLocale = getPathLocale(locale)

  let path = `${pathLocale}/${slug}`
  path = path.endsWith('/') ? path : `${path}/`

  return path
}

export const getPathLocale = (locale: Locale) => {
  return locale === i18n.defaultLocale ? `/` : `/${locale}`
}
