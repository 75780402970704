/* eslint-disable react-hooks/exhaustive-deps */
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useParams } from 'next/navigation'
import { useWindowSize } from 'react-use'
import { useScroll } from 'framer-motion'
import { Locale } from '@/config/locales'
import { GlobalType } from '@/types'
import { HeaderContext, HeaderStateProps } from '@/contexts/HeaderProvider'

export type useHeaderProps = {
  locale: Locale
  menu: GlobalType['navbarTop']['navbarItem']
  cigarBar: GlobalType['GlobalCigarBar']
}

const MIN_WIDTH_FOR_DESKTOP_MENU = 1024

const useHeader = () => {
  const [isDesktopMenu, setIsDesktopMenu] = useState(true)
  const menuRef = useRef(null)

  const { state, setState } = useContext(HeaderContext)
  const { scrollY, scrollYProgress } = useScroll()
  const { width } = useWindowSize()
  const params = useParams()

  const { share, blur, hidden, expanded, opened, solidButton } = state

  const dispatch = useCallback((payload: Partial<HeaderStateProps>) => {
    setState((state) => ({ ...state, ...payload }))
  }, [])

  const solidButtonOnDesktop = useMemo(
    () => width >= MIN_WIDTH_FOR_DESKTOP_MENU,
    [width]
  )

  const isDesktopAndOpenedMenu = useMemo(
    () => width >= MIN_WIDTH_FOR_DESKTOP_MENU && opened,
    [width, opened]
  )

  const toggleHeader = useCallback(() => {
    const prev = scrollY.getPrevious()
    const current = scrollY.get()

    const scrollDown = prev < current
    const isScrollDownEnough = current >= 10
    const isScrollTopEnough = current <= 400

    const newHeaderState: Partial<HeaderStateProps> = {}

    if (scrollDown && isScrollDownEnough) {
      newHeaderState.hidden = true
      newHeaderState.share = 'visible'
    } else {
      newHeaderState.hidden = false
      newHeaderState.share = 'visible_with_menu'
    }

    newHeaderState.blur = isScrollDownEnough

    if (isScrollTopEnough) {
      newHeaderState.share = 'hidden'
    }

    dispatch(newHeaderState)
  }, [scrollY])

  useEffect(() => {
    dispatch({ solidButton: solidButtonOnDesktop ? 1 : 0 })
    isDesktopAndOpenedMenu && dispatch({ opened: false })
  }, [dispatch, solidButtonOnDesktop, isDesktopAndOpenedMenu])

  useEffect(() => {
    if (state.opened) return

    toggleHeader()

    const unsubY = scrollY.on('change', toggleHeader)

    return () => {
      unsubY()
    }
  }, [scrollY, state.opened])

  useEffect(() => {
    setIsDesktopMenu(width >= MIN_WIDTH_FOR_DESKTOP_MENU)
  }, [width])

  const onHandleMenu = useCallback(
    (active: HeaderStateProps['opened']) => {
      dispatch({ opened: active })
    },
    [dispatch]
  )

  const onHoverDropdown = useCallback(
    (id: HeaderStateProps['expanded']) => {
      if (width < MIN_WIDTH_FOR_DESKTOP_MENU) return
      dispatch({ expanded: expanded === id ? false : id })
    },
    [dispatch, expanded, width]
  )

  const showShareBar = params?.category && params?.slug

  return {
    menuRef,
    share,
    hidden,
    blur,
    expanded,
    opened,
    solidButton,
    isDesktopMenu,
    onHandleMenu,
    onHoverDropdown,
    scrollYProgress,
    showShareBar
  }
}

export default useHeader
