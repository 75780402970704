const i18n = {
  defaultLocale: 'en',
  locales: [
    'en',
    'pt',
    'es',
    'ar',
    'nl',
    'fr',
    'de',
    'it',
    'pl',
    'ru',
    'sv',
    'ja',
    'ko',
    'th',
    'tr',
    'cs',
    'nb',
    'uk',
    'zh',
    'zh-Hant'
  ]
} as const

export type Locale = (typeof i18n)['locales'][number]

const locales: Record<
  Locale,
  { lang: string; englishLabel: string; label: string }
> = {
  en: {
    lang: 'en',
    englishLabel: 'English',
    label: 'English'
  },
  es: {
    lang: 'es',
    englishLabel: 'Spanish',
    label: 'Español'
  },
  pt: {
    lang: 'pt',
    englishLabel: 'Portuguese',
    label: 'Português'
  },
  ar: {
    lang: 'ar',
    englishLabel: 'Arabic',
    label: 'العربية'
  },
  nl: {
    lang: 'nl',
    englishLabel: 'Dutch',
    label: 'Dutch'
  },
  fr: {
    lang: 'fr',
    englishLabel: 'French',
    label: 'Français'
  },
  de: {
    lang: 'de',
    englishLabel: 'German',
    label: 'Deutsch'
  },
  it: {
    lang: 'it',
    englishLabel: 'Italian',
    label: 'Italiano'
  },
  pl: {
    lang: 'pl',
    englishLabel: 'Polish',
    label: 'Polski'
  },
  ru: {
    lang: 'ru',
    englishLabel: 'Russian',
    label: 'Русский'
  },
  sv: {
    lang: 'sv',
    englishLabel: 'Swedish',
    label: 'Svenska'
  },
  ja: {
    lang: 'ja',
    englishLabel: 'Japanese',
    label: '日本語'
  },
  ko: {
    lang: 'ko',
    englishLabel: 'Korean',
    label: '한국어'
  },
  th: {
    lang: 'th',
    englishLabel: 'Thai',
    label: 'ไทย'
  },
  tr: {
    lang: 'tr',
    englishLabel: 'Turkish',
    label: 'Türkçe'
  },
  zh: {
    lang: 'zh',
    englishLabel: 'Chinese',
    label: '简体中文'
  },
  'zh-Hant': {
    lang: 'zh-Hant',
    englishLabel: 'Chinese Traditional',
    label: '繁體中文'
  },
  cs: {
    lang: 'cs',
    englishLabel: 'Czech',
    label: 'Čeština'
  },
  nb: {
    lang: 'nb',
    englishLabel: 'Norwegian',
    label: 'Norsk'
  },
  uk: {
    lang: 'uk',
    englishLabel: 'Ukrainian',
    label: 'українська'
  }
}

const rtlLocales = ['ar']

const getLocaleDir = (locale: Locale) => {
  return rtlLocales.includes(locale) ? 'rtl' : 'ltr'
}

export { i18n, locales, getLocaleDir }
