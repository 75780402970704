import { tv, VariantProps } from '@/utils/tv'

export const share = tv({
  slots: {
    base: ['flex items-center gap-3'],
    item: []
  }
})

export type ShareVariantProps = VariantProps<typeof share>
