import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./articulat/ArticulatCF-Regular.woff2\",\"weight\":\"400\"},{\"path\":\"./articulat/ArticulatCF-Medium.woff2\",\"weight\":\"500\"},{\"path\":\"./articulat/ArticulatCF-DemiBold.woff2\",\"weight\":\"600\"},{\"path\":\"./articulat/ArticulatCF-Bold.woff2\",\"weight\":\"700\"}],\"display\":\"swap\",\"variable\":\"--font-articulat\"}],\"variableName\":\"articulat\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/button/button-group.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/button/button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/floating-banner/floating-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/footer/footer.brand.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/google-analytics/google-analytics.tracking.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/header/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/lang-switcher/lang-switcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/qrcode/qrcode.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/social/social.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AlternateURLProvider"] */ "/vercel/path0/src/contexts/AlternateURLProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderProvider"] */ "/vercel/path0/src/contexts/HeaderProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TrackingProvider"] */ "/vercel/path0/src/contexts/TrackingProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
