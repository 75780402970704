import { tv, VariantProps } from '@/utils/tv'

export const social = tv({
  slots: {
    base: ['flex gap-6'],
    item: [],
    link: [
      'text-gray-80 hover:text-gray-40',
      'inline-flex',
      'rounded-sm',
      'transition-colors duration-300',
      'outline-none',
      'focus:outline-offset-4 focus:outline-acqua-500'
    ],
    icon: ['h-5 w-5']
  }
})

export type SocialVariantProps = VariantProps<typeof social>
