import { useMeasure } from 'react-use'
import { CigarBarVariantProps, cigarBarVariant } from './cigar-bar.variants'
import { useEffect } from 'react'
import { isBrowser } from '@/utils/env'
import { GlobalType } from '@/types'

export type useCigarBarProps = CigarBarVariantProps & {
  className?: string
  data?: GlobalType['GlobalCigarBar']
}

export const useCigarBar = (props: useCigarBarProps) => {
  const { className = '', data } = props
  const [ref, { height }] = useMeasure()

  useEffect(() => {
    if (height === undefined || !isBrowser) return

    document.body.style.setProperty('--cigar-bar-height', `${height}px`)

    return () => {
      document.body.style.removeProperty('--cigar-bar-height')
    }
  }, [height])

  const styles = cigarBarVariant()

  return {
    ref,
    className,
    styles,
    data
  }
}
