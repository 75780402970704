'use client'

import { useEffect, useMemo, useState } from 'react'
import { usePathname } from 'next/navigation'
import { useWindowScroll } from 'react-use'
import { motion } from 'framer-motion'
import { Text } from '../text'
import QRCodeImage from './qrcode.svg'

const ACTIVATE_SCROLL_Y = 250

const madeForRegex = /.*made-for\/[^\/]+\/[^\/]+.*/

const variants = {
  hidden: { opacity: 0, y: '100px' },
  visible: { opacity: 1, y: '0' }
}

const QRCode = () => {
  const [visible, setVisible] = useState(false)
  const pathname = usePathname()
  const { y } = useWindowScroll()

  const isMadeForPage = useMemo(() => madeForRegex.test(pathname), [pathname])

  useEffect(() => {
    if (isMadeForPage) {
      visible && setVisible(false)
      return
    }

    if (y >= ACTIVATE_SCROLL_Y) {
      !visible && setVisible(true)
    } else {
      visible && setVisible(false)
    }
  }, [isMadeForPage, visible, y])

  return (
    <motion.div
      initial="hidden"
      animate={visible ? 'visible' : 'hidden'}
      variants={variants}
      transition={{ duration: 0.4 }}
      className="fixed bottom-6 right-6 z-40 !hidden w-28 overflow-hidden rounded-xl bg-acqua-700 p-2 leading-none shadow-3xl lg:!block"
    >
      <Text
        className="mb-1 text-center"
        size="tiny"
        weight="semibold"
        uppercase
      >
        Download
      </Text>
      <div className="rounded-lg bg-white p-2">
        <QRCodeImage className="h-full w-full shrink-0" />
      </div>
    </motion.div>
  )
}

export default QRCode
