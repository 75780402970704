'use client'

import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import { pageView } from '@/utils/google'

const GoogleAnalyticsTracking = () => {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    if (pathname) {
      pageView(pathname)
    }
  }, [pathname, searchParams])

  return null
}

export default GoogleAnalyticsTracking
